const videos = require('./video/*.mp4');
const posters = require('./video/*.jpg');

const video = document.getElementById('video-background');
const source = document.createElement('source');
const files = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const randomId = files[Math.floor(Math.random() * files.length)];

// video.addEventListener('ended', () => {
//     console.log('video ended');
//     video.play();
// });

video.poster = posters[randomId];
source.src = videos[randomId];
video.appendChild(source);
video.defaultPlaybackRate = 0.2;
video.playbackRate = 4.0;
video.play();

